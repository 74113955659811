/* eslint-disable max-len */

import React, { useState } from 'react';
import useStyles from './styles';

const StatebleCard = ({ title, description, children, isEmpty = true }) => {
  const [isShowContent, setShowContent] = useState(!isEmpty);
  const classes = useStyles();

  const handleShowContent = () => {
    setShowContent(true);
  };

  if (isShowContent) {
    return children;
  }

  return (
    <div className={classes.root} onClick={handleShowContent}>
      <div className={classes.content}>
        <h4 className={classes.title}>
          {title}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={classes.icon}
            width="12"
            height="8"
            viewBox="0 0 12 8"
          >
            <path
              d="M9.863 7.603l-3.765-3.92-3.96 3.92a1.187 1.187 0 01-1.77 0c-.488-.536-.488-1.392 0-1.92L5.204.803c.24-.264.563-.4.885-.4s.645.136.885.4l4.657 4.88c.488.528.488 1.392 0 1.928-.487.52-1.282.52-1.77-.008z"
              fillRule="evenodd"
            />
          </svg>
        </h4>
        <div className={classes.description}>{description}</div>
      </div>
    </div>
  );
};

export default StatebleCard;
