import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useStyles from './styles';

const MAX_TITLE_SIZE = 50;

const Row = ({
  item: { id, title, imgSrc, price, sku },
  isSelectedItem,
  onAddItem,
  onCancelItem,
}) => {
  const classes = useStyles();
  const checkboxClasses = classNames(classes.checkbox, {
    [classes.selected]: isSelectedItem,
    [classes.unselected]: !isSelectedItem,
  });

  const skuClasses = classNames(classes.tableRight, classes.sku);

  const handleClick = () => {
    if (isSelectedItem) {
      onCancelItem(id);
    } else {
      onAddItem(id);
    }
  };

  const shortTitle =
    title && title.length > MAX_TITLE_SIZE
      ? `${title.slice(0, MAX_TITLE_SIZE)}...`
      : title;

  return (
    <div className={classes.row} onClick={handleClick}>
      <div className={classes.tableLeft}>
        <div className={classes.checkboxWrapper}>
          <div className={checkboxClasses} />
        </div>
        <div className={classes.imageWrapper}>
          <img src={imgSrc} className={classes.image} />
        </div>
        <div>{shortTitle}</div>
      </div>
      <div className={classes.tableRight}>
        <div className={skuClasses}>{sku}</div>
        <div className={classes.tableRight}>{price}</div>
      </div>
    </div>
  );
};

Row.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    imgSrc: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    sku: PropTypes.string.isRequired,
  }),
  isSelectedItem: PropTypes.bool.isRequired,
  onAddItem: PropTypes.func.isRequired,
  onCancelItem: PropTypes.func.isRequired,
};

export default Row;
