import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const NamedArea = ({ title, subtitle, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.namedArea}>
      <div className={classes.header}>
        <div className={classes.titles}>
          <div className={classes.title}>{title}</div>
          <div className={classes.subtitle}>{subtitle}</div>
        </div>
      </div>
      <div className={classes.body}>{children}</div>
    </div>
  );
};

NamedArea.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default NamedArea;
