import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import useStyles from './styles';

const Collapsible = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleCollapsible = () => {
    setIsOpen(!isOpen);
  };
  const classes = useStyles();
  return (
    <div
      className={classNames({
        [classes.collapsible]: true,
        [classes.opened]: isOpen,
      })}
    >
      <div
        className={classes.header}
        onClick={handleCollapsible}
        onKeyDown={handleCollapsible}
        role="button"
        tabIndex="0"
        aria-label="collapsible"
      >
        {title}
      </div>
      <div className={classes.body}>{children}</div>
    </div>
  );
};

Collapsible.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default Collapsible;
