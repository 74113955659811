import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import useStyles from './styles';

const Card = ({ type = 'normal', className, children }) => {
  const classes = useStyles({ type });
  return (
    <div className={classNames(className, classes.card)}>
      <div className={classes.paddings}>{children}</div>
    </div>
  );
};

Card.defaultProps = {
  type: 'normal',
};

Card.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default Card;
