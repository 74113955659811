import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const IconButton = ({ icon, handle, disabled = false }) => {
  const classes = useStyles({ icon });
  return (
    <button
      type="button"
      className={classes.iconButton}
      onClick={handle}
      disabled={disabled}
    >
      <span className={classes.icon} />
    </button>
  );
};

IconButton.defaultProps = {
  disabled: false,
};

IconButton.propTypes = {
  icon: PropTypes.string,
  handle: PropTypes.func,
  disabled: PropTypes.bool,
};

export default IconButton;
