import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  fieldset: {
    composes: 'fieldset fieldset--no-label',
  },
  field: {
    composes: 'field',
  },
  fieldMedium: {
    composes: 'field--medium',
  },
  fieldFocus: {
    composes: 'field--focus',
  },
  icon: {
    composes: 'fieldset__svg-icon',
  },
  label: {
    composes: 'field__label',
  },
  input: {
    composes: 'field__input',
  },
  placeholder: {
    composes: 'field__placeholder',
  },
  success: {
    composes: 'field-state--success',
  },
  close: {
    composes: 'field-state--close',
  },
  error: {
    composes: 'field__error',
  },
});

export default useStyles;
