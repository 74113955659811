import React from 'react';
import PropTypes from 'prop-types';

const TitledItemsList = ({ list }) => {
  const itemList = list.map(({ title, content, isRawHtml }) => (
    <li className="titled-items-list__item titled-item" key={title}>
      <div className="titled-item__title">{title}</div>
      {isRawHtml ? (
        <div
          className="titled-item__content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      ) : (
        <div className="titled-item__content">{content}</div>
      )}
    </li>
  ));
  return <ul className="titled-items-list">{itemList}</ul>;
};

TitledItemsList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      isRawHtml: PropTypes.bool.isRequired,
    })
  ).isRequired,
};

export default TitledItemsList;
