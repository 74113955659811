import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  fieldset: {
    composes: 'fieldset fieldset--no-label',
  },
  field: {
    composes: 'field field--medium',
    '& .react-datepicker-popper': {
      zIndex: 150,
    },
  },
  focus: {
    composes: 'field--focus',
  },
  icon: {
    composes: 'fieldset__svg-icon',
  },
  label: {
    composes: 'field__label',
  },
  input: {
    composes: 'field__input',
  },
  placeholder: {
    composes: 'field__placeholder',
  },
  success: {
    composes: 'field-state--success',
  },
  close: {
    composes: 'field-state--close',
  },
  error: {
    composes: 'field__error',
    display: 'none',
  },
});

export default useStyles;
