import React from 'react';
import ReactDatePicker from 'react-datepicker';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SuccessIcon from '../Icons/SuccessIcon.jsx';
import CloseIcon from '../Icons/CloseIcon.jsx';
import 'react-datepicker/dist/react-datepicker.css';
import useStyles from './styles';

const DatePicker = ({ title, placeholder, defaultValue, onChange }) => {
  const [focus, setFocus] = React.useState(false);

  const classes = useStyles();

  return (
    <div className={classes.fieldset}>
      <div
        className={classNames({
          [classes.field]: true,
          [classes.focus]: focus,
        })}
      >
        <span className={classes.icon} />
        <label className={classes.label}>{title}</label>
        <ReactDatePicker
          className={classes.input}
          onChange={onChange}
          selected={defaultValue}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
        <div className={classes.placeholder}>{placeholder}</div>
        <span className={classes.success}>
          <SuccessIcon />
        </span>
        <span className={classes.close}>
          <CloseIcon />
        </span>
      </div>
      <div className={classes.error} aria-hidden="true" />
    </div>
  );
};

DatePicker.propTypes = {
  title: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
};

export default DatePicker;
