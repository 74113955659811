import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  button: ({ type, size }) => ({
    composes: `btn btn-${type} btn-${size}`,
  }),
  svgIcon: {
    composes: 'svg-icon',
  },
});

export default useStyles;
