import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    composes: 'a-card a-card--has-hover a-card--normal',
  },
  content: {
    composes: 'a-card__paddings paddings-0-2-15',
  },
  title: {
    composes: 'text-default info',
  },
  description: {
    composes: 'text-default',
  },
  icon: {
    height: '16px',
    fill: 'currentColor',
    transform: 'rotate(90deg) translateX(3px)',
    marginLeft: '5px',
  },
});

export default useStyles;
