import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  pagingBar: {
    marginTop: '15px',
  },
  text: {
    fontSize: '13px',
    color: '#262a2e',
    whiteSpace: 'nowrap',
    paddingRight: '8px',
    marginLeft: '5px',
  },
  textBold: {
    fontWeight: 'bold',
    color: '#262a2e',
  },
  buttonPrev: {
    composes:
      'btn btn-default btn-small btn-icon pagination__nav pagination__nav--prev',
    width: 'auto !important',
  },
  iconLeft: {
    composes: 'icon-arr-left',
    fontFamily: 'font_icons',
  },
  buttonNext: {
    composes:
      'btn btn-default btn-small btn-icon pagination__nav pagination__nav--next',
    width: 'auto !important',
  },
  iconRight: {
    composes: 'icon-arr-right',
    fontFamily: 'font_icons',
  },
});

export default useStyles;
