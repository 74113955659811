import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  namedArea: {
    composes: 'named-area',
  },
  header: {
    composes: 'named-area__header',
  },
  titles: {
    composes: 'named-area__titles',
  },
  title: {
    composes: 'named-area__title',
  },
  subtitle: {
    composes: 'named-area__subtitle',
  },
  body: {
    composes: 'named-area__body',
  },
});

export default useStyles;
