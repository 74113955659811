import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const Pagination = ({
  currentPage,
  totalPages,
  isFirstPage,
  isLastPage,
  onLoadNextPage,
  onLoadPrevPage,
  pageText,
  ofText,
}) => {
  const handlePrevPage = (event) => {
    event.preventDefault();
    onLoadPrevPage();
  };

  const handleNextPage = (event) => {
    event.preventDefault();
    onLoadNextPage();
  };

  const classes = useStyles();

  return (
    <div className={classes.pagingBar}>
      <span className={classes.text}>{pageText}:</span>
      <button
        type="button"
        className={classes.buttonPrev}
        disabled={isFirstPage()}
        onClick={handlePrevPage}
      >
        <span className={classes.iconLeft} />
      </button>
      <span className={classes.text}>{currentPage}</span>
      <button
        type="button"
        className={classes.buttonNext}
        disabled={isLastPage()}
        onClick={handleNextPage}
      >
        <span className={classes.iconRight} />
      </button>
      <span className={classes.text}>
        {ofText}&#160;
        <span className={classes.textBold}>{totalPages}</span>
      </span>
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.string,
  totalPages: PropTypes.string,
  isFirstPage: PropTypes.func,
  isLastPage: PropTypes.func,
  onLoadNextPage: PropTypes.func,
  onLoadPrevPage: PropTypes.func,
  pageText: PropTypes.string,
  ofText: PropTypes.string,
};

export default Pagination;
