import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  card: ({ type }) => ({
    composes: `a-card a-card--${type}`,
    backgroundColor: '#CDE8FC',
  }),
  cardWithChanges: {
    composes: 'a-card--info',
  },
  cardWithErrors: {
    composes: 'a-card--error',
    backgroundColor: '#feeceb !important',
  },
  paddings: {
    composes: 'a-card__paddings a-card__paddings--default-background',
    height: 'auto !important',
  },
  paddingsWithoutChanges: {
    borderColor: 'white !important',
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: 450,
  },
  loading: {
    composes: 'btn-loading',
  },
  saveButton: {
    composes: 'btn btn-primary',
  },
  cancelButton: {
    composes: 'btn btn-default',
  }
});

export default useStyles;
