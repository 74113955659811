import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const Button = ({ text, icon, type = 'primary', size = 'medium', handle }) => {
  const classes = useStyles({ type, size });

  return (
    <button type="button" className={classes.button} onClick={handle}>
      <span className={classes.svgIcon}>{icon}</span>
      <span>{text}</span>
    </button>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.element,
  handle: PropTypes.func,
};

export default Button;
