import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useOutsideClick from '../hooks/useOutsideClick';
import useStyles from './styles';

const Dropdown = ({ onClick, options, title, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  const dropdownClasses = classNames(classes.dropdownToggle, {
    [classes.opened]: isOpen,
    [classes.disabled]: disabled,
  });

  const handleDropdownClick = () => {
    if (disabled) return;

    setIsOpen(!isOpen);
  };

  const handleOptionClick = (optionKey) => () => {
    setIsOpen(false);
    onClick(optionKey);
  };

  const ref = useOutsideClick(() => {
    setIsOpen(false);
  });

  return (
    <div className={classes.dropdown}>
      <div className={dropdownClasses} onClick={handleDropdownClick} ref={ref}>
        <button
          type="button"
          className={classes.hiddenButton}
          aria-hidden="false"
        />
        <div className={classes.dropdownButton} disabled={disabled}>
          <span className={classes.dropdownContainer}>
            <span className={classes.dropdownTitle}>{title}</span>
          </span>
          <span className="icon-arr-down" />
        </div>
        <div className={classes.dropdownList}>
          <ul>
            {options.map(({ key, value }) => (
              <li key={key} onClick={handleOptionClick(key)}>
                <a>{value}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  onClick: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

Dropdown.defaultProps = {
  disabled: false,
};

export default Dropdown;
