import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  iconableBlock: {
    composes: 'iconable-block',
  },
  infographics: {
    composes: 'iconable-block__infographics',
  },
  icon: {
    composes: 'iconable-block__icon',
  },
  content: {
    composes: 'iconable-block__content',
    height: 'auto !important',
  },
  block: {
    composes: 'cta-block',
  },
  blockCentral: {
    composes: 'cta-block__central',
  },
  title: {
    composes: 'cta-block__title',
  },
  text: {
    composes: 'cta-block__content',
  },
  button: {
    composes: 'cta-block__cta',
  },
});

export default useStyles;
