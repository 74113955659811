import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  formArea: {
    composes: 'form-area',
  },
  title: {
    composes: 'form-area__title',
  },
  content: {
    composes: 'form-area__content',
  },
});

export default useStyles;
