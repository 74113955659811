import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { all, uniq, includes, without } from 'ramda';
import classNames from 'classnames';
import useOutsideClick from '../hooks/useOutsideClick';
import Row from './Row';
import useStyles from './styles';
import Pagination from '../Pagination';
import Loader from '../Loader';

const DEFAULT_CANCEL_BUTTON_TEXT = 'Cancel';
const DEFAULT_ASSIGN_BUTTON_TEXT = 'Assign Selected';
const DEFAULT_NAME_COLUMN_TEXT = 'name';
const DEFAULT_SKU_COLUMN_TEXT = 'sku';
const DEFAULT_PRICE_COLUMN_TEXT = 'price';
const DEFAULT_PAGE_TEXT = 'Page';
const DEFAULT_PLACEHOLDER_TEXT = 'Start typing product’s name or SKU';

const MultiSelect = ({
  items,
  selectedItemIds: defaultSelectedItemIds,
  isLoading,
  keyword,
  pagination: {
    currentPage,
    totalPages,
    isFirstPage,
    isLastPage,
    onLoadPrevPage,
    onLoadNextPage,
  },
  labels = {},
  onChangeKeyword,
  onSaveButtonClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItemIds, setSelectedItemIds] = useState(
    defaultSelectedItemIds
  );

  const classes = useStyles();
  const fieldsetClasses = classNames(classes.fieldset, {
    [classes.dropdownOpened]: isOpen,
  });
  const fieldClasses = classNames(classes.field, {
    [classes.focused]: isOpen || keyword,
  });

  const pageItemsIds = items.map(({ id }) => id);
  const isSelectedItem = (itemId) => includes(itemId, selectedItemIds);
  const isAllSelected = all(isSelectedItem, pageItemsIds);

  const checkboxClasses = classNames(classes.checkbox, {
    [classes.selected]: isAllSelected,
    [classes.unselected]: !isAllSelected,
  });

  const ref = useOutsideClick(() => {
    setIsOpen(false);
    setSelectedItemIds(defaultSelectedItemIds);
  });

  const handleAddItem = (id) => setSelectedItemIds((ids) => [...ids, id]);

  const handleCancelItem = (id) =>
    setSelectedItemIds((ids) => without([id], ids));

  const handleChange = (event) => {
    setIsOpen(true);
    onChangeKeyword(event.target.value);
  };

  const handleFocus = () => {
    setIsOpen(true);
  };

  const handleCheckboxClick = () => {
    if (isAllSelected) {
      setSelectedItemIds((ids) => without(pageItemsIds, ids));
    } else {
      setSelectedItemIds((ids) => uniq([...ids, ...pageItemsIds]));
    }
  };

  const handleSaveButtonClick = () => {
    onSaveButtonClick(selectedItemIds);
    setIsOpen(false);
  };

  const handleCancelButtonClick = () => {
    setSelectedItemIds(defaultSelectedItemIds);
    setIsOpen(false);
  };

  useEffect(() => {
    setSelectedItemIds(defaultSelectedItemIds);
  }, [defaultSelectedItemIds]);

  return (
    <div className="fieldsets-batch" ref={ref}>
      <div className={fieldsetClasses}>
        <div className={fieldClasses}>
          <span className="fieldset__svg-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#9C9C9C"
              viewBox="0 0 16 16"
              focusable="false"
            >
              <path d="M5.8 3.1c1-.9 2.1-1.5 3.6-1.5 1.4 0 2.6.5 3.6 1.5.9 1 1.5 2.1 1.5 3.6 0 1.4-.5 2.6-1.5 3.6-1 .9-2.1 1.5-3.6 1.5-1.3 0-2.6-.5-3.5-1.5-1-1-1.5-2.1-1.5-3.6-.1-1.4.3-2.5 1.4-3.6M.8 16c.2 0 .4-.1.6-.2l3.8-3.7.3.2c1 .7 2.3 1.2 3.8 1.2.9 0 1.8-.2 2.6-.5.8-.4 1.5-.8 2.2-1.5.6-.6 1.1-1.3 1.5-2.1.3-.9.4-1.7.4-2.6 0-.9-.2-1.8-.5-2.6-.4-.8-.8-1.5-1.5-2.2-.6-.6-1.3-1.1-2.1-1.5C11 .2 10.2 0 9.3 0c-.9 0-1.8.2-2.6.5-.8.4-1.5.8-2.2 1.5-.6.6-1.1 1.3-1.4 2.1-.4.9-.5 1.7-.5 2.5 0 1.4.4 2.6 1.2 3.8l.2.3-3.7 3.7c-.2.2-.3.4-.3.7 0 .2.1.4.2.6.3.3.5.3.6.3" />
            </svg>
          </span>
          <input
            type="text"
            className="field__input"
            onChange={handleChange}
            onFocus={handleFocus}
          />
          <div className="field__placeholder">
            {labels.placeholder ? labels.placeholder : DEFAULT_PLACEHOLDER_TEXT}
          </div>
          <span
            className="field__arrow"
            aria-hidden="true"
            style={{ display: 'none' }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 26 26"
              focusable="false"
            >
              <path d="M7.85 10l5.02 4.9 5.27-4.9c.65-.66 1.71-.66 2.36 0 .65.67.65 1.74 0 2.4l-6.45 6.1c-.33.33-.76.5-1.18.5-.43 0-.86-.17-1.18-.5l-6.21-6.1c-.65-.66-.65-1.74 0-2.41.66-.65 1.72-.65 2.37.01z" />
            </svg>
          </span>
        </div>
        <div className="list-dropdown list-dropdown--with-buttons">
          <div className={classes.dropdownContent}>
            {isLoading && <Loader />}
            <div className={classes.table}>
              <div className={classes.head}>
                <div className={classes.tableLeft}>
                  <div className={classes.checkboxWrapper}>
                    <div
                      className={checkboxClasses}
                      onClick={handleCheckboxClick}
                    />
                  </div>
                  <div>
                    {labels.name ? labels.name : DEFAULT_NAME_COLUMN_TEXT}
                  </div>
                </div>
                <div className={classes.tableRight}>
                  <div className={classes.tableRight}>
                    {labels.sku ? labels.sku : DEFAULT_SKU_COLUMN_TEXT}
                  </div>
                  <div className={classes.tableRight}>
                    {labels.price ? labels.price : DEFAULT_PRICE_COLUMN_TEXT}
                  </div>
                </div>
              </div>
              {items.map((item) => (
                <Row
                  key={item.id}
                  item={item}
                  isSelectedItem={includes(item.id, selectedItemIds)}
                  onAddItem={handleAddItem}
                  onCancelItem={handleCancelItem}
                />
              ))}
            </div>
          </div>
          <div className={classes.buttonsWrapper}>
            <div>
              <button
                type="button"
                className="btn btn-primary btn-small"
                onClick={handleSaveButtonClick}
              >
                {labels.assignButton
                  ? labels.assignButton
                  : DEFAULT_ASSIGN_BUTTON_TEXT}
              </button>
              <button
                type="button"
                className="btn btn-default btn-small"
                onClick={handleCancelButtonClick}
              >
                {labels.cancelButton
                  ? labels.cancelButton
                  : DEFAULT_CANCEL_BUTTON_TEXT}
              </button>
            </div>
            <div>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                isFirstPage={isFirstPage}
                isLastPage={isLastPage}
                onLoadPrevPage={onLoadPrevPage}
                onLoadNextPage={onLoadNextPage}
                pageText={labels.pageText ? labels.pageText : DEFAULT_PAGE_TEXT}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MultiSelect.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      imgSrc: PropTypes.string.isRequired,
      price: PropTypes.string.isRequired,
      sku: PropTypes.string.isRequired,
    })
  ).isRequired,
  keyword: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedItemIds: PropTypes.arrayOf(PropTypes.string),
  onChangeKeyword: PropTypes.func.isRequired,
  onSaveButtonClick: PropTypes.func.isRequired,
  labels: PropTypes.shape({
    cancelButton: PropTypes.string.isRequired,
    assignButton: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    sku: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    pageText: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
  }),
};

export default MultiSelect;
