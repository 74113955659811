import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const Checkbox = ({ handler, checked, text }) => {
  const classes = useStyles();
  return (
    <div className={classes.checkbox}>
      <label>
        <input
          type="checkbox"
          checked={checked}
          className={classes.input}
          onChange={handler}
        />
        <span className={classes.label} />
        <span className={classes.text}>{text}</span>
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  handler: PropTypes.func,
  checked: PropTypes.bool,
  text: PropTypes.string,
};

export default Checkbox;
