import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  fieldset: {
    composes: 'fieldset fieldset--combo fieldset--no-label',
  },
  field: {
    composes: 'field field--medium list-dropdown-medium',
  },
  opened: {
    composes: 'opened field--opened field--focus',
  },
  icon: {
    composes: 'fieldset__svg-icon',
  },
  input: {
    composes: 'field__input',
  },
  dropdown: {
    composes: 'list-dropdown',
  },
  anchor: {
    composes: 'gwt-Anchor',
  },
  arrow: {
    composes: 'field__arrow',
  },
  error: {
    composes: 'field__error',
  },
});

export default useStyles;
