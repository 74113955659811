import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useOutsideClick from '../hooks/useOutsideClick';
import useStyles from './styles';

const getContainerStyles = (isInsideIframe, onCalculateTopPosition) => {
  if (!isInsideIframe) return {};

  const styles = {
    position: 'absolute',
    top: onCalculateTopPosition(),
  };

  return styles;
};

const Dialog = ({
  isOpened,
  iconComponent,
  title,
  approveButtonText,
  cancelButtonText,
  isInsideIframe = false,
  onCalculateTopPosition,
  onApprove,
  onCancel,
  onClose,
}) => {
  const classes = useStyles();
  const modalClasses = classNames(classes.mainOverlay, {
    [classes.opened]: isOpened,
  });

  const ref = useOutsideClick(() => {
    if (isOpened) {
      onClose();
    }
  });

  const containerStyles = getContainerStyles(
    isInsideIframe,
    onCalculateTopPosition
  );

  return (
    <div className={modalClasses}>
      <div className="main-popup main-popup_center main-popup_animation">
        <div
          className="main-popup__container"
          ref={ref}
          style={containerStyles}
        >
          <div className="main-popup__subcontainer">
            <div className="main-popup__logo">{iconComponent}</div>
            <h3 className="main-popup__title">
              <span className="gwt-InlineHTML">{title}</span>
            </h3>
            <div
              className="main-popup__text"
              aria-hidden="true"
              style={{ display: 'none' }}
            />
            <div
              className="main-popup__content"
              aria-hidden="true"
              style={{ display: 'none' }}
            />
            <div className="main-popup__buttons">
              <button
                type="button"
                className="btn btn-primary btn-medium"
                tabIndex="1000"
                onClick={onApprove}
              >
                {approveButtonText}
              </button>
              <button
                type="button"
                className="btn btn-default btn-medium"
                tabIndex="1001"
                onClick={onCancel}
              >
                {cancelButtonText}
              </button>
            </div>
            <div
              className="main-popup__footer-text"
              aria-hidden="true"
              style={{ display: 'none' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Dialog.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  iconComponent: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  approveButtonText: PropTypes.string.isRequired,
  cancelButtonText: PropTypes.string.isRequired,
  onApprove: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Dialog;
