import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import useStyles from './styles';

const DEFAULT_SAVE_BUTTON_TEXT = 'save';
const DEFAULT_CANCEL_BUTTON_TEXT = 'cancel';

const StatusPanel = ({
  type,
  isSaving,
  hasErrors,
  errorMessage,
  labels,
  onSave,
  onCancel,
}) => {
  const classes = useStyles({ type });
  const saveButtonClasses = classNames(classes.saveButton, {
    [classes.loading]: isSaving,
  });

  return (
    <div className="a-card__paddings">
      <div className="cta-block">
        <div className="cta-block__central">
          {hasErrors ? errorMessage : ''}
        </div>
        <div className="cta-block__cta">
          <div className={classes.buttonsWrapper}>
            <button
              disabled={isSaving}
              type="button"
              className={classes.cancelButton}
              onClick={onCancel}
            >
              <span>{labels.cancel}</span>
            </button>
            <button
              disabled={isSaving || hasErrors}
              type="button"
              className={saveButtonClasses}
              onClick={onSave}
            >
              <span>{labels.save}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// NOTE: maybe be rename it? It's component just for saving
const StatusCard = ({
  type = 'normal',
  children,
  isDirty,
  isSaving,
  hasErrors,
  errorMessage,
  labels,
  onSave,
  onCancel,
}) => {
  const classes = useStyles({ type });
  const cardClasses = classNames(classes.card, {
    [classes.cardWithErrors]: hasErrors,
    [classes.cardWithChanges]: isDirty,
  });

  const paddingsClasses = classNames(classes.paddings, {
    [classes.paddingsWithoutChanges]: !isDirty,
  });

  return (
    <div className={cardClasses}>
      <div className={paddingsClasses}>{children}</div>
      {isDirty && (
        <StatusPanel
          type={type}
          isSaving={isSaving}
          labels={labels}
          onSave={onSave}
          onCancel={onCancel}
          hasErrors={hasErrors}
          errorMessage={errorMessage}
        />
      )}
    </div>
  );
};

StatusCard.defaultProps = {
  type: 'normal',
  hasErrors: false,
  errorMessage: '',
  labels: {
    save: DEFAULT_SAVE_BUTTON_TEXT,
    cancel: DEFAULT_CANCEL_BUTTON_TEXT,
  },
};

StatusCard.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  labels: PropTypes.shape({
    save: PropTypes.string,
    cancel: PropTypes.string,
  }),
};

export default StatusCard;
