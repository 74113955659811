import React from 'react';
import { SketchPicker } from 'react-color';
import PropTypes from 'prop-types';

const ColorPicker = ({ defaultColor, onChange }) => {
  const handleChange = (newColor) => {
    onChange(newColor.hex);
  };
  return <SketchPicker color={defaultColor || ''} onChange={handleChange} />;
};

ColorPicker.propTypes = {
  defaultColor: PropTypes.string,
  onChange: PropTypes.func,
};

export default ColorPicker;
