import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';
import Button from '../Button';
import NamedArea from '../NamedArea';
import Card from '../Card';
import { openInNewTab } from '../utils/openInNewTab';

const ExtendedCrossSells = ({
  header,
  text,
  title,
  subtitle,
  buttonText,
  icon,
  link,
}) => {
  const classes = useStyles();

  const handleClick = useCallback(() => {
    openInNewTab(link);
  }, [link]);

  return (
    <div className={classes.crosssell}>
      <NamedArea title={title} subtitle={subtitle}>
        <Card>
          <div className={classes.card}>
            <div className={classes.icon}>{icon}</div>
            <div className={classes.content}>
              <div className={classes.headerWrapper}>
                <div className={classes.header}>{header}</div>
              </div>
              <div className={classes.text}>{text}</div>
            </div>
            <div className={classes.buttonWrapper}>
              <Button text={buttonText} type="primary" handle={handleClick} />
            </div>
          </div>
        </Card>
      </NamedArea>
    </div>
  );
};

ExtendedCrossSells.propTypes = {
  header: PropTypes.string,
  buttonText: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  link: PropTypes.string,
  icon: PropTypes.object,
};

export default ExtendedCrossSells;
