import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  dropdown: {
    composes: 'status-block__actions-dropdown',
  },
  dropdownToggle: {
    composes: 'btn-group dropdown-toggle',
  },
  opened: {
    composes: 'opened',
  },
  disabled: {
    composes: 'disabled',
  },
  dropdownList: {
    composes: 'list-dropdown list-dropdown-medium',
  },
  hiddenButton: {
    composes: 'btn btn-default btn-medium',
    display: 'none',
  },
  dropdownButton: {
    composes:
      'btn btn-default btn-dropdown btn-medium list-dropdown-no-general-text',
  },
  dropdownContainer: {
    composes: 'btn-dropdown-container',
  },
  dropdownTitle: {
    composes: 'actions',
  },
});

export default useStyles;
