import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const FormArea = ({ title, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.formArea}>
      <div className={classes.title}>{title}</div>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

FormArea.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default FormArea;
