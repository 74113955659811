import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SuccessIcon from '../Icons/SuccessIcon.jsx';
import CloseIcon from '../Icons/CloseIcon.jsx';
import useStyles from './styles';

const ActionableInput = ({
  title,
  placeholder,
  onChange,
  defaultValue = '',
  disabled = false,
  maxLength,
  prefix,
  suffix,
}) => {
  const handleChange = ({ target: { value } }) => {
    onChange(value);
  };

  const [focus, setFocus] = React.useState(false);
  const classes = useStyles();
  return (
    <div
      className={classNames({
        [classes.fieldset]: true,
        [classes.withPrefix]: prefix,
        [classes.withSuffix]: suffix,
      })}
    >
      <div className={classes.wrapper}>
        <div
          className={classNames({
            [classes.field]: true,
            [classes.fieldMedium]: true,
            [classes.fieldFocus]: focus,
          })}
        >
          <span className={classes.icon} />
          <label className={classes.label}>{title}</label>
          <input
            type="text"
            className={classes.input}
            onChange={handleChange}
            value={defaultValue}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            disabled={disabled}
            maxLength={maxLength}
          />
          <div className={classes.placeholder}>{placeholder}</div>
          <span className={classes.success}>
            <SuccessIcon />
          </span>
          <span className={classes.close}>
            <CloseIcon />
          </span>
        </div>
        {prefix && <div className={classes.prefixField}>{prefix}</div>}
        {suffix && <div className={classes.suffixField}>{suffix}</div>}
      </div>
      <div
        className={classes.error}
        aria-hidden="true"
        style={{ display: 'none' }}
      />
    </div>
  );
};

ActionableInput.defaultProps = {
  disabled: false,
  inputType: 'string',
};

ActionableInput.propTypes = {
  title: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  inputType: PropTypes.string,
};

export default ActionableInput;
