import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  mainOverlay: {
    composes: 'main-overlay',
    display: 'none',
  },
  opened: {
    display: 'block',
  },
});

export default useStyles;
