import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const SettingsPageHeader = ({ title, subtitle }) => {
  const classes = useStyles();
  return (
    <div className={classes.header}>
      <div className={classes.titles}>
        <h1 className={classes.title}>{title}</h1>
        <div className={classes.subtitle}>{subtitle}</div>
      </div>
    </div>
  );
};

SettingsPageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default SettingsPageHeader;
