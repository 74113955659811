import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ArrowIcon from '../Icons/ArrowIcon.jsx';
import useOutsideClick from '../hooks/useOutsideClick';
import useStyles from './styles';

const CategorySelect = ({ onChange, defaultValue, categories }) => {
  const getLabelSelectedCategory = (newValue) => {
    const [selectedCategory] = categories.filter(
      ({ value }) => String(value) === String(newValue)
    );
    return selectedCategory ? selectedCategory.label : '';
  };

  const [keyword, setKeyword] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [availableCategories, setAvailableCategories] = useState(categories);

  const handleChangeOption = (value) => (event) => {
    event.stopPropagation();
    setIsOpen(false);
    setKeyword(getLabelSelectedCategory(value));
    setAvailableCategories(categories);
    onChange(value);
  };

  const handleChangeInput = ({ target: { value } }) => {
    setKeyword(value);
    if (value.length === 0) {
      setAvailableCategories(categories);
      return;
    }
    const filterAvailableCategories = categories.filter(({ label }) =>
      label.includes(value)
    );

    setAvailableCategories(filterAvailableCategories);
  };

  useEffect(() => {
    setKeyword(getLabelSelectedCategory(defaultValue));
  }, [defaultValue]);

  const ref = useOutsideClick(() => {
    setIsOpen(false);
  });

  const classes = useStyles();

  return (
    <div
      className={classes.fieldset}
      onClick={() => setIsOpen(true)}
      onKeyDown={() => setIsOpen(true)}
      role="button"
      tabIndex="0"
      ref={ref}
    >
      <div
        className={classNames({
          [classes.field]: true,
          [classes.opened]: isOpen,
        })}
      >
        <span className={classes.icon} />
        <input
          type="text"
          className={classes.input}
          value={keyword}
          onChange={handleChangeInput}
        />
        <div className={classes.dropdown}>
          <ul>
            {availableCategories.map(({ value, label }, i) => (
              <li
                key={value}
                value={value}
                onClick={handleChangeOption(value)}
                onKeyDown={handleChangeOption(value)}
                role="menuitem"
                tabIndex={i + 1}
              >
                <a className={classes.anchor}>{label}</a>
              </li>
            ))}
          </ul>
        </div>
        <span className={classes.arrow}>
          <ArrowIcon />
        </span>
      </div>
      <div className={classes.error} aria-hidden="true" />
    </div>
  );
};

CategorySelect.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  categories: PropTypes.array,
};

export default CategorySelect;
