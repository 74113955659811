import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const Toggles = ({
  handler,
  checked: initialState,
  textOn,
  textOf,
  type = 'normal',
}) => {
  const [state, setState] = useState(initialState);
  const classes = useStyles({ type });
  useEffect(() => {
    setState(initialState);
  }, [initialState]);

  return (
    <label className={classes.checkbox}>
      <input type="checkbox" checked={state} name="" onChange={handler} />
      <div data-on={textOn} data-off={textOf}>
        <div />
      </div>
    </label>
  );
};

Toggles.propTypes = {
  handler: PropTypes.func,
  checked: PropTypes.bool,
  textOn: PropTypes.string,
  textOf: PropTypes.string,
  type: PropTypes.oneOf(['micro', 'tiny', 'normal', 'big']),
};

export default Toggles;
