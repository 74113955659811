import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  body: {
    composes: 'settings-page__body',
  },
  grid: {
    display: 'grid',
  },
});

export default useStyles;
