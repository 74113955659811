import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  crosssell: {
    padding: 20,
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  card: {
    padding: 15,
    display: 'flex',
  },
  content: {
    margin: [0, 30],
  },
  header: {
    fontFamily: 'Gotham Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 16,
  },
  text: {
    fontFamily: 'SF UI Text',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    margin: [8, 0, 20, 0]
  },
  icon: {
    display: 'block',
  },
  headerWrapper: {
    display: 'flex',
  },
  '@media (max-width: 600px)': {
    buttonWrapper: {
      margin: [0, 30],
    },
    card: {
      flexDirection: 'column',
    },
    icon: {
      display: 'none',
    },
  }
});

export default useStyles;
