import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  checkbox: {
    composes: 'custom-checkbox',
    minHeight: '20px',
    width: 'auto !important',
  },
  input: {
    composes: 'custom-checkbox__input',
  },
  label: {
    composes: 'custom-checkbox__label',
  },
  text: {
    composes: 'custom-checkbox__text',
  },
});

export default useStyles;
