import React from 'react';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SuccessIcon from '../Icons/SuccessIcon.jsx';
import CloseIcon from '../Icons/CloseIcon.jsx';
import useStyles from './styles';

const Input = ({
  title,
  onChange,
  defaultValue = '',
  disabled = false,
  inputType = 'string',
  maxLength,
}) => {
  const handleChangeStringType = ({ target: { value } }) => {
    onChange(value);
  };

  const handleChangeNumberType = ({ value }) => {
    onChange(value);
  };

  const [focus, setFocus] = React.useState(false);
  const classes = useStyles();
  return (
    <div className={classes.fieldset}>
      <div
        className={classNames({
          [classes.field]: true,
          [classes.fieldMedium]: true,
          [classes.fieldFocus]: focus,
        })}
      >
        <span className={classes.icon} />
        <label className={classes.label}>{title}</label>
        {inputType === 'string' && (
          <input
            type="text"
            className={classes.input}
            onChange={handleChangeStringType}
            value={defaultValue}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            disabled={disabled}
            maxLength={maxLength}
          />
        )}
        {inputType === 'number' && (
          <NumberFormat
            className={classes.input}
            onValueChange={handleChangeNumberType}
            value={defaultValue}
            thousandSeparator
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
          />
        )}
        <div className={classes.placeholder}>{title}</div>
        <span className={classes.success}>
          <SuccessIcon />
        </span>
        <span className={classes.close}>
          <CloseIcon />
        </span>
      </div>
      <div
        className={classes.error}
        aria-hidden="true"
        style={{ display: 'none' }}
      />
    </div>
  );
};

Input.defaultProps = {
  disabled: false,
  inputType: 'string',
};

Input.propTypes = {
  title: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  inputType: PropTypes.string,
};

export default Input;
