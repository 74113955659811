import React from 'react';
import useStyles from './styles';

const Loader = () => {
  const classes = useStyles();
  return (
    <div className={classes.standardLoader}>
      <div className={classes.loadingPanel}>
        <div className={classes.loader}>
          <div className={classes.spinnerOuter} />
          <div className={classes.spinnerInner} />
        </div>
      </div>
    </div>
  );
};

export default Loader;
