import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  collapsible: {
    composes: 'collapsible',
  },
  opened: {
    composes: 'collapsible--opened',
  },
  header: {
    composes: 'collapsible__header',
  },
  body: {
    composes: 'collapsible__body',
  },
});

export default useStyles;
