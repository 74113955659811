import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  standardLoader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-44px',
    marginLeft: '-44px',
    zIndex: '30',
  },
  loadingPanel: {
    fontSize: '14px',
    animation: 'zoom 0.3s 1 linear, fadein 0.5s 1 linear',
  },
  loader: {
    zIndex: 30,
  },
  spinnerOuter: {
    width: '88px',
    height: '88px',
    background: [
      'transparent',
      `url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='88' height='88' viewBox='0 0 88 88'%3E%3Cpath fill='none' stroke='%23068eef' stroke-linecap='round' stroke-width='3' d='M64.8 79.5C58.4 83.3 51 85.5 43 85.5 27 85.5 13 76.6 5.8 63.5M64.4 6.2C77 13.6 85.5 27.3 85.5 43 85.5 50.7 83.4 58 79.8 64.2M.5 43C.5 19.5 19.5.5 43 .5' transform='translate(1 1)'/%3E%3C/svg%3E")`,
      'center center no-repeat',
    ].join(' '),
    backgroundSize: 'contain',
    animation: 'spinright 0.8s infinite linear',
  },
  spinnerInner: {
    marginLeft: '25px',
    marginTop: '-65px',
    width: '40px',
    height: '40px',
    background: [
      'transparent',
      `url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40'%3E%3Cpath fill='%23068eef' d='M20 39.5C9.2 39.5.5 30.8.5 20S9.2.5 20 .5 39.5 9.2 39.5 20c0 .8-.7 1.5-1.5 1.5s-1.5-.7-1.5-1.5c0-9.1-7.4-16.5-16.5-16.5S3.5 10.9 3.5 20 10.9 36.5 20 36.5c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5z'/%3E%3C/svg%3E")`,
      'center center no-repeat',
    ].join(' '),
    backgroundSize: 'contain',
    animation: 'spinleft 0.5s infinite linear',
  },
});

export default useStyles;
