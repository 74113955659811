import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  fieldset: {
    composes: 'fieldset fieldset--no-label fieldset--dropdown fieldset--icon',
  },
  dropdownContent: {
    composes: 'list-dropdown__content',
    maxHeight: 'none !important',
  },
  checkboxWrapper: {
    margin: [10, 10, 10, 0],
  },
  buttonsWrapper: {
    composes: 'list-dropdown__buttons-wrapper list-dropdown__buttons-wrapper--show',
    display: 'flex',
    justifyContent: 'space-between',
    padding: [0, 16],
    alignItems: 'baseline',
  },
  dropdownOpened: {
    composes: 'fieldset--dropdown-opened',
  },
  column: {
    display: 'flex',
  },
  listElement: {
    composes: 'list-element list-element--note list-element--has-hover',
    border: '1px solid #c5ccd3 !important',
    justifyContent: 'space-between',
  },
  price: {
    composes: 'list-element__price-value nowrap',
    fontSize: 14,
  },
  field: {
    composes: 'field',
  },
  focused: {
    composes: 'field--focus',
  },
  checkbox: {
    composes: 'dropdown-list-item__icon dropdown-list-item__icon--type ',
  },
  selected: {
    composes: 'dropdown-list-item__icon--checked',
  },
  unselected: {
    composes: 'dropdown-list-item__icon--unchecked',
  },
  table: {
    composes: 'flex-table',
  },
  head: {
    composes: 'flex-table__head',
    padding: [0, 20],
  },
  row: {
    composes: 'flex-table__row',
    fontWeight: 600,
    padding: [10, 20],
  },
  tableLeft: {
    composes: 'flex-table__col flex-table__col--align-left',
    display: 'flex',
    alignItems: 'center',
  },
  tableRight: {
    composes: 'flex-table__col flex-table__col--align-right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
  },
  fieldsets: {
    composes: 'fieldsets-batch fieldsets-batch--horizontal',
    alignItems: 'baseline',
  },
  imageWrapper: {
    display: 'flex',
    overflow: 'hidden',
    flex: '0 0 36px',
    alignItems: 'start',
    width: 36,
    height: 36,
    textAlign: 'center',
    marginRight: 10,
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  sku: {
    color: '#7D8D9E',
  }
});

export default useStyles;
