import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import useStyles from './styles';

const Bubble = ({ title, text, position = 'top' }) => {
  const classes = useStyles({ position });
  return (
    <div className={classes.bubble}>
      <div className={classNames(classes.info, classes.position)}>
        <div className={classes.title}>{title}</div>
        <div className={classes.text}>{text}</div>
      </div>
    </div>
  );
};

Bubble.defaultProps = {
  position: 'top',
};

Bubble.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  position: PropTypes.string,
};

export default Bubble;
