import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import useStyles from './styles';

const IconableBlock = ({
  title,
  BlockIcon,
  buttonText,
  ButtonIcon,
  handler,
  children,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.iconableBlock}>
      <div className={classes.infographics}>
        <span>
          <div className={classes.icon}>
            <BlockIcon />
          </div>
        </span>
      </div>
      <div className={classes.content}>
        <div className={classes.block}>
          <div className={classes.blockCentral}>
            <div className={classes.title}>{title}</div>
            <div className={classes.text}>{children}</div>
          </div>
          <div className={classes.button}>
            <Button text={buttonText} icon={<ButtonIcon />} handle={handler} />
          </div>
        </div>
      </div>
    </div>
  );
};

IconableBlock.propTypes = {
  title: PropTypes.string,
  BlockIcon: PropTypes.element,
  buttonText: PropTypes.string,
  ButtonIcon: PropTypes.element,
  handler: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default IconableBlock;
