import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  card: ({ type }) => ({
    composes: `a-card a-card--${type}`,
  }),
  paddings: {
    composes: 'a-card__paddings',
    height: 'auto !important',
  },
});

export default useStyles;
