import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  iconButton: {
    border: '0px',
    backgroundColor: 'rgba(0, 50, 50, 0)',
    color: '#068eef',
    '&:hover': {
      color: '#0463a7',
    },
  },
  icon: ({ icon }) => ({
    composes: `icon icon-${icon}`,
  }),
});

export default useStyles;
