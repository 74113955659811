import React from 'react';

const SyncIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76">
    <path d="M75.6 41.2l-8.5-8.5c-.1-.1-.4-.4-1.1-.4-.5 0-1 .4-1.1.4l-8.1 8.1c-.6.6-.6 1.5 0 2.1.3.3.7.4 1.1.4.4 0 .8-.1 1.1-.4l7.1-7 7.4 7.4c.3.3.7.4 1.1.4.4 0 .8-.1 1.1-.4.4-.6.4-1.5-.1-2.1zM.4 34.8l8.5 8.5c.1.1.4.4 1.1.4.5 0 1-.4 1.1-.4l8.1-8.1c.6-.6.6-1.5 0-2.1-.3-.3-.7-.4-1.1-.4-.4 0-.8.1-1.1.4l-7.1 7-7.4-7.4c-.3-.3-.7-.4-1.1-.4-.4 0-.8.1-1.1.4-.4.6-.4 1.5.1 2.1zM38 67.5c-10.2 0-19.5-5.2-25-13.8-.4-.7-.2-1.6.5-2.1.7-.4 1.6-.2 2.1.5 4.9 7.8 13.3 12.4 22.4 12.4 14.6 0 26.5-11.9 26.5-26.5 0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5c0 16.3-13.2 29.5-29.5 29.5zM10 39.4c-.8 0-1.5-.7-1.5-1.5 0-9.5 4.7-18.4 12.4-23.9 6.4-4.6 14.2-6.4 22-5.1 7.8 1.3 14.6 5.5 19.2 12 .5.7.3 1.6-.3 2.1-.7.5-1.6.3-2.1-.3-4.1-5.8-10.2-9.6-17.2-10.7-7-1.2-14 .5-19.8 4.6-6.9 4.9-11.1 13-11.1 21.5-.1.6-.8 1.3-1.6 1.3z" />
    <path fill="none" d="M0 0h76v76H0z" />
  </svg>
);

export default SyncIcon;
