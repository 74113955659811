import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  header: {
    composes: 'settings-page__header',
  },
  titles: {
    composes: 'settings-page__titles settings-page__titles--left',
  },
  title: {
    composes: 'settings-page__title',
  },
  subtitle: {
    composes: 'settings-page__subtitle',
  },
});

export default useStyles;
