import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  bubble: {
    composes: 'bubble',
    display: 'none',
    marginTop: '5px',
  },
  info: {
    composes: 'bubble-info',
  },
  position: ({ position }) => ({
    composes: `bubble-${position}`,
  }),
  title: {
    composes: 'bbl-title',
  },
  text: {
    composes: 'bbl-text',
  },
});

export default useStyles;
