import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';
import Button from '../Button';
import { openInNewTab } from '../utils/openInNewTab';

const CrossSells = ({ header, text, buttonText, icon, link }) => {
  const classes = useStyles();

  const handleClick = useCallback(() => {
    openInNewTab(link);
  }, [link]);

  return (
    <div className={classes.card}>
      <div className={classes.icon}>{icon}</div>
      <div className={classes.context}>
        <div className={classes.header}>{header}</div>
        <div className={classes.text}>{text}</div>
        <Button text={buttonText} type="default" handle={handleClick} />
      </div>
    </div>
  );
};

CrossSells.propTypes = {
  header: PropTypes.string,
  buttonText: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
  icon: PropTypes.object,
};

export default CrossSells;
