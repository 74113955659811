import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import useStyles from './styles';

const Modal = ({ title, children, open, handleClose }) => {
  const classes = useStyles();
  return (
    <div
      className={classNames({
        [classes.modal]: true,
        [classes.open]: open,
      })}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className={classes.backdrop}
        onClick={handleClose}
        onKeyDown={handleClose}
        role="button"
        tabIndex="0"
        aria-label="close modal"
      />
      <div className={classes.dialog} role="document">
        <div className={classes.content}>
          <div className={classes.header}>
            <button
              type="button"
              className={classes.close}
              onClick={handleClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 className={classes.title}>{title}</h4>
          </div>
          <div className={classes.body}>{children}</div>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default Modal;
