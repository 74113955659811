import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  save: {
    display: 'flex',
    position: 'fixed',
    right: '20px',
    bottom: '20px',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      fill: '#068eef',
      marginLeft: '5px',
      width: '50px',
      animationName: '$rotation',
      animationDuration: '5s',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'linear',
    },
  },
  '@keyframes rotation': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  text: {
    color: '#068eef',
  },
});

export default useStyles;
