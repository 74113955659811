import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const SettingsPageBody = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.grid}>
      <div className={classes.body}>{children}</div>
    </div>
  );
};

SettingsPageBody.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default SettingsPageBody;
