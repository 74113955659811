import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const DEFAULT_TITLE = 'Color Picker';
const DEFAULT_CUSTOM_COLOR_LABEL = 'Custom color';

const FieldStateSuccess = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26px"
    height="26px"
    viewBox="0 0 26 26"
    focusable="false"
  >
    <path d="M5 12l5.02 4.9L21.15 4c.65-.66 1.71-.66 2.36 0 .65.67.65 1.74 0 2.4l-12.3 14.1c-.33.33-.76.5-1.18.5-.43 0-.86-.17-1.18-.5l-6.21-6.1c-.65-.66-.65-1.74 0-2.41.65-.65 1.71-.65 2.36.01z" />
  </svg>
);

const FieldStateClose = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path d="M15.6,15.5c-0.53,0.53-1.38,0.53-1.91,0L8.05,9.87L2.31,15.6c-0.53,0.53-1.38,0.53-1.91,0 c-0.53-0.53-0.53-1.38,0-1.9l5.65-5.64L0.4,2.4c-0.53-0.53-0.53-1.38,0-1.91c0.53-0.53,1.38-0.53,1.91,0l5.64,5.63l5.74-5.73 c0.53-0.53,1.38-0.53,1.91,0c0.53,0.53,0.53,1.38,0,1.91L9.94,7.94l5.66,5.65C16.12,14.12,16.12,14.97,15.6,15.5z" />
  </svg>
);

const ButtonIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    focusable="false"
  >
    <path
      d="M16.747.82l.433.433a2 2 0 01.054 2.772c-.098.16-.215.311-.354.45l-2.983 2.984a1 1 0 01-.086 1.316l-.115.115a1 1 0 01-1.317.085l-8.08 8.08A2.372 2.372 0 01.946 13.7l8.08-8.08a1 1 0 01.085-1.316l.115-.115a1 1 0 011.316-.086l2.984-2.983c.139-.139.29-.256.45-.353L13.92.82a2 2 0 012.828 0zm-6.89 5.645l-8.073 8.074a1.186 1.186 0 001.573 1.77l.104-.093 8.073-8.074-1.677-1.677z"
      fillRule="nonzero"
    />
  </svg>
);

const StandardColorPicker = ({ currentColor, palette, labels, onChange }) => {
  const colorPickerClass = '.color-picker';
  const handleChange = (event) => onChange(event.detail.color);

  useEffect(() => {
    /* eslint-disable no-new */
    new window.ColorPicker({
      el: colorPickerClass,
      value: currentColor,
      palette,
    });
    document
      .querySelector(colorPickerClass)
      .addEventListener('onChange', handleChange);

    return () => {
      document
        .querySelector(colorPickerClass)
        .addEventListener('onChange', handleChange);
    };
  }, [palette]);

  return (
    <div className="color-picker">
      <span className="color-picker__title">{labels.title}</span>
      <div className="color-picker__body">
        <div className="color-palette-group" />
        <div className="color-picker__custom-settings">
          <div className="fieldsets-batch fieldsets-batch--horizontal">
            <div className="fieldset fieldset--with-label">
              <div>
                <div className="fieldset__field-wrapper">
                  <div className="field field--medium">
                    <span className="fieldset__svg-icon" />{' '}
                    <label className="field__label">{labels.customColor}</label>{' '}
                    <input type="text" className="field__input" maxLength="9" />
                    <div className="field__placeholder">Custom color</div>
                    <span className="field-state--success">
                      <FieldStateSuccess />
                    </span>
                    <span className="field-state--close">
                      <FieldStateClose />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-icon btn-default btn-medium"
            >
              <span className="svg-icon">
                <ButtonIcon />
              </span>
            </button>
          </div>
          <div className="color-picker__picker" />
        </div>
      </div>
    </div>
  );
};

StandardColorPicker.defaultProps = {
  labels: {
    title: DEFAULT_TITLE,
    customColor: DEFAULT_CUSTOM_COLOR_LABEL,
  },
};

StandardColorPicker.propTypes = {
  currentColor: PropTypes.string.isRequired,
  palette: PropTypes.arrayOf(PropTypes.string),
  labels: PropTypes.shape({
    title: PropTypes.string,
    customColor: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
};

export default StandardColorPicker;
