import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  modal: {
    composes: 'modal fade',
    display: 'none',
    textAlign: 'center',
    padding: '0 !important',
    position: 'fixed',
    zIndex: 1040000,
    opacity: 0,
    '&:before': {
      content: '',
      display: 'inline-block',
      height: '100%',
      verticalAlign: 'middle',
      marginRight: '-4px',
    },
  },
  open: {
    composes: 'in',
    display: 'block',
    position: 'fixed',
    left: 0,
    right: 0,
    top: 'auto',
    bottom: 0,
    overflow: 'visible',
    opacity: 1,
  },
  backdrop: {
    composes: 'modal-backdrop fade in',
    width: '100vw',
    height: '100vh',
    opacity: '0.5 !important',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 0,
    backgroundColor: 'black',
  },
  dialog: {
    composes: 'modal-dialog',
    position: 'relative',
    display: 'inline-block',
    textAlign: 'left',
    verticalAlign: 'middle',
    width: '100%',
    zIndex: 1040010,
    transform: 'translate(0, 0)',
  },
  content: {
    composes: 'modal-content',
    position: 'relative',
    marginTop: '-100px',
    boxShadow: '0 3px 9px rgba(0, 0, 0, 0.5)',
    borderRadius: '3px',
    backgroundColor: 'white',
  },
  header: {
    composes: 'modal-header',
    padding: '15px',
    borderBottom: '1px solid #e5e5e5',
  },
  close: {
    composes: 'close',
    marginTop: '-2px',
    padding: 0,
    cursor: 'pointer',
    background: 'transparent',
    border: 0,
    float: 'right',
    fontSize: '21px',
    fontWeight: 'bold',
    lineHeight: 1,
    color: 'black',
    textShadow: '0 1px 0 white',
    opacity: '0.2',
  },
  title: {
    composes: 'modal-title',
    margin: 0,
  },
  body: {
    composes: 'modal-body',
    '& .field__input': {
      height: '45px',
      marginTop: '-3px',
    },
    position: 'relative',
    padding: '15px',
  },
  '@media (min-width: 911px)': {
    dialog: {
      width: '800px',
      margin: '30px auto',
    },
  },
});

export default useStyles;
