import React from 'react';

/* eslint-disable max-len */
const PlusIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" focusable="false">
    <path d="M6.712 5.314H11v1.39H6.712V11H5.267V6.706H1V5.314h4.267V1h1.446v4.314z" />
  </svg>
);

export default PlusIcon;
